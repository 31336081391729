import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Img from 'gatsby-image';
import Layout from '../templates/Layout';
import { navigate } from 'gatsby-plugin-intl';

const About = ({ data: { about } }) => {
  // typeof window !== undefined && navigate('/blog');
  return (
    <Layout seo={about.seoMetaTags}>
      <article className="sheet">
        {/* <div className="sheet__inner"> */}
        <h1 className="sheet__title">{about.title}</h1>
        {/* <Img className="donate__banner" fluid={about.photo.fluid} /> */}
        {/* <p className="sheet__lead">{about.subtitle}</p> */}
        <div
          className="sheet__body"
          dangerouslySetInnerHTML={{
            __html: about.bioNode.childMarkdownRemark.html,
          }}
        />
        {/* </div> */}
      </article>
    </Layout>
  );
};

export default About;

export const query = graphql`
  query AboutQuery($locale: String!) {
    about: datoCmsAboutPage(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subtitle
      photo {
        fluid(maxWidth: 900, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      bioNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
